import React, { useState } from 'react';
import { LActText, LActButton, LActTextInput, LActRow } from 'components/index';
import axios from 'axios';
import { EnumButtonActionType, EnumButtonType } from 'constants/enums';

const ManagementEventsNotificationScreen = (): React.ReactElement => {
  const [serverMessage, setServerMessage] = useState<{
    message: string;
    type: string;
  }>({
    message: '',
    type: '',
  });
  const [notificationDescriptionEN, setNotificationDescriptionEN] =
    useState<string>('');
  const [notificationDescriptionDE, setNotificationDescriptionDE] =
    useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notificationTitleEN, setNotificationTitleEN] = useState<string>('');
  const [notificationTitleDE, setNotificationTitleDE] = useState<string>('');
  const [notificationWebPage, setNotificationWebPage] = useState<string>('');

  const onNotificationTitleENChange = (value: string) => {
    setNotificationTitleEN(value);
    setServerMessage({
      message: '',
      type: '',
    });
  };

  const onNotificationDescriptionENChange = (value: string) => {
    setNotificationDescriptionEN(value);
    setServerMessage({
      message: '',
      type: '',
    });
  };

  const onNotificationTitleDEChange = (value: string) => {
    setNotificationTitleDE(value);
    setServerMessage({
      message: '',
      type: '',
    });
  };

  const onNotificationDescriptionDEChange = (value: string) => {
    setNotificationDescriptionDE(value);
    setServerMessage({
      message: '',
      type: '',
    });
  };

  const onNotificationWebPageChange = (value: string) => {
    setNotificationWebPage(value);
    setServerMessage({
      message: '',
      type: '',
    });
  };

  const onSendNotificationClicked = () => {
    if (notificationWebPage?.length === 0) {
      setServerMessage({
        message: 'Please enter the Url',
        type: 'error',
      });
    } else if (notificationTitleEN?.length === 0) {
      setServerMessage({
        message: 'Please enter notification title EN',
        type: 'error',
      });
    } else if (notificationDescriptionEN?.length === 0) {
      setServerMessage({
        message: 'Please enter notification description EN',
        type: 'error',
      });
    } else if (notificationDescriptionDE?.length === 0) {
      setServerMessage({
        message: 'Please enter notification description DE',
        type: 'error',
      });
    } else if (notificationTitleDE?.length === 0) {
      setServerMessage({
        message: 'Please enter notification title DE',
        type: 'error',
      });
    } else {
      setIsLoading(true);
      let data = JSON.stringify({
        titleEn: notificationTitleEN?.trim(),
        descriptionEn: notificationDescriptionEN?.trim(),
        titleDe: notificationTitleDE?.trim(),
        descriptionDe: notificationDescriptionDE?.trim(),
        webPage: notificationWebPage?.trim(),
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/api/v1/admin/notifications/general/notification/events`,
        headers: {
          access_token: process.env.REACT_APP_LETSACT_API_API_KEY,
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios
        .request(config)
        .then(response => {
          setServerMessage({
            message: response?.data?.item,
            type: 'success',
          });
          setIsLoading(false);
        })
        .catch(error => {
          setServerMessage({
            message: 'An error happened!',
            type: 'error',
          });
          setIsLoading(false);
        });
    }
  };

  const ENABLE_SEND_NOTIFICATIONS_BUTTON =
    notificationDescriptionEN?.length === 0 &&
    notificationTitleEN?.length === 0 &&
    notificationDescriptionDE?.length === 0 &&
    notificationTitleDE?.length === 0 &&
    notificationWebPage?.length === 0
      ? true
      : false;

  return (
    <div className="pl-6">
      <div className=" mr-3 mt-6">
        <div className="mt-16">
          <LActRow>
            <div className="w-1/2">
              <LActTextInput
                type={'text'}
                value={notificationTitleEN}
                containerStyle="w-[400px] mt-3"
                placeHolder={'Notification Title EN'}
                onChange={onNotificationTitleENChange}
              />

              <LActTextInput
                type={'text'}
                containerStyle="w-[400px] mt-3"
                value={notificationDescriptionEN}
                placeHolder={'Notification Description EN'}
                onChange={onNotificationDescriptionENChange}
              />

              <LActTextInput
                type={'text'}
                value={notificationWebPage}
                containerStyle="w-[400px] mt-3"
                placeHolder={'Notification We page'}
                onChange={onNotificationWebPageChange}
              />
            </div>

            <div className="w-1/2 ml-6">
              <LActTextInput
                type={'text'}
                value={notificationTitleDE}
                containerStyle="w-[400px] mt-3"
                placeHolder={'Notification Title DE'}
                onChange={onNotificationTitleDEChange}
              />

              <LActTextInput
                type={'text'}
                containerStyle="w-[400px] mt-3"
                value={notificationDescriptionDE}
                placeHolder={'Notification Description DE'}
                onChange={onNotificationDescriptionDEChange}
              />
            </div>
          </LActRow>

          <LActButton
            useLoading={isLoading}
            testID={'login-button'}
            containerStyle={'mt-4'}
            type={EnumButtonType.NORMAL}
            text={'Send General Notification'}
            onClick={onSendNotificationClicked}
            actionType={EnumButtonActionType.BUTTON}
            disabled={ENABLE_SEND_NOTIFICATIONS_BUTTON}
          />

          {serverMessage?.message && (
            <LActText
              text={serverMessage?.message}
              numberOfLines={'line-clamp-2'}
              className={`text-sm ml-3 mt-1 w-full text-semibold`}
              textColor={
                serverMessage?.type === 'error' ? 'text-error' : 'text-success'
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ManagementEventsNotificationScreen;
