import React, { useEffect, useState } from 'react';
import {
  LActRow,
  LActLoader,
  LActTextInput,
  OfferTemplate,
} from 'components/index';
import {
  EnumErrorType,
  EnumManagementOfferSearchType,
  EnumOffersFilterType,
} from 'constants/enums';
import useGetAdminOffersPagination from './api/useGetAdminOffersPagination';
import { OFFERS_DETAILS_SCREEN } from 'constants/route';
import { useNavigate } from 'react-router-dom';
import { Offer } from 'api/schemas/offer';
import { CPagination, CPaginationItem } from '@coreui/react';
import { OfferSearchRadioButton } from '../utils';
import { usePostApiV1OfferSearch } from 'api/endpoints/queries';
import { delay } from 'utils/global/functions';

const ManagementOfferScreen = (): React.ReactElement => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState<Array<number>>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [paginationPage, setPaginationPage] = useState<number>(1);
  const [searchQueryPreference, setSearchQueryPreference] =
    useState<EnumManagementOfferSearchType>(
      EnumManagementOfferSearchType.OFFER_NAME,
    );

  const [searchResults, setSearchResults] = useState<Array<Offer>>();

  const itemsPerPage = 12;

  const { data, isLoading, totalPages, totalItems } =
    useGetAdminOffersPagination({
      size: itemsPerPage,
      page: paginationPage,
    });

  const OFFER_SEARCH_QUERY = usePostApiV1OfferSearch({
    request: {
      headers: {
        'Content-Type': 'application/json',
        api_key: process.env.REACT_APP_LETSACT_API_API_KEY,
        access_token: process.env.REACT_APP_LETSACT_API_API_KEY,
      },
    },
  });

  //   @ts-ignore
  const total = Math.ceil(totalPages / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const visiblePagination = pagination.slice(
    startIndex,
    startIndex + itemsPerPage,
  );

  useEffect(() => {
    if (searchQuery?.length > 0) {
      OFFER_SEARCH_QUERY?.mutateAsync({
        data: {
          query: searchQuery,
          scope: searchQueryPreference?.split('_')[0],
        },
      })
        // @ts-ignore
        .then(response => {
          // @ts-ignore
          setSearchResults(response?.items);
        })
        .catch(error => error);
    } else {
      setSearchResults([]);
      delay(1000).then(() => setPaginationPage(1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if (data && totalPages) {
      const PAGINATION_ITEMS = [];

      for (let index = 0; index <= totalPages; index++) {
        PAGINATION_ITEMS.push(index);
      }
      setPagination(PAGINATION_ITEMS);
    }
  }, [data, totalPages]);

  const onOfferClicked = (item: Offer) => {
    navigate(OFFERS_DETAILS_SCREEN, {
      state: {
        item: item,
      },
    });
  };

  let BADGE_STATUS = '';
  let BADGE_TEXT_COLOR = '';
  let BADGE_BACKGROUND_COLOR = '';

  const handleBadgeStyle = (status: string) => {
    switch (status) {
      case EnumOffersFilterType.PUBLISHED:
        BADGE_TEXT_COLOR = 'text-green';
        BADGE_BACKGROUND_COLOR = 'bg-greenLight';
        BADGE_STATUS = 'Published';
        break;

      case EnumOffersFilterType.EXPIRED:
        BADGE_TEXT_COLOR = 'text-error';
        BADGE_BACKGROUND_COLOR = 'bg-pink';
        BADGE_STATUS = 'Expired';
        break;

      case EnumOffersFilterType.FILLED:
        BADGE_TEXT_COLOR = 'text-textSecondary';
        BADGE_BACKGROUND_COLOR = 'bg-skyBlue';
        BADGE_STATUS = 'Filled';
        break;

      case EnumOffersFilterType.PAUSED:
        BADGE_TEXT_COLOR = 'text-textSecondary';
        BADGE_BACKGROUND_COLOR = 'bg-neutral600';
        BADGE_STATUS = 'Paused';
        break;

      default:
        break;
    }
  };

  if (isLoading) {
    return (
      <div>
        <div className={'flex mt-20 justify-center items-center'}>
          <LActLoader color={'tint'} />
        </div>
      </div>
    );
  }

  const SEARCH_BOX_PLACEHOLDER = `Search by  ,,${searchQueryPreference.replace(
    '_',
    ' ',
  )}"`;

  return (
    <div className="pl-6">
      <div>
        <LActTextInput
          type={'text'}
          maxLength={250}
          testID={'org-name-input'}
          containerStyle={'mt-6 w-1/2'}
          messageType={EnumErrorType.ERROR}
          placeHolder={SEARCH_BOX_PLACEHOLDER}
          onChange={value => setSearchQuery(value)}
          message={
            searchQuery && searchResults?.length === 0
              ? 'No results found!'
              : ''
          }
        />

        <div className="mt-1">
          <span>Search By:</span>

          {OfferSearchRadioButton.map(item => {
            const ACTIVE_ITEM_RADIO =
              searchQueryPreference === item.type
                ? 'bg-orange'
                : 'bg-neutral500';

            const ACTIVE_ITEM_TEXT =
              searchQueryPreference === item.type
                ? 'text-orange'
                : 'text-neutral500';

            return (
              <LActRow
                className="items-center cursor-pointer"
                key={`Offer-Search-Management-${item.type}`}
                onClick={() => setSearchQueryPreference(item.type)}
              >
                <span
                  className={`w-4 h-4 rounded-full ${ACTIVE_ITEM_RADIO}`}
                ></span>
                <span className={`ml-3 ${ACTIVE_ITEM_TEXT}`}>{item.name}</span>
              </LActRow>
            );
          })}
        </div>
      </div>

      {searchQuery?.length === 0 && (
        <CPagination
          align="center"
          style={{ marginTop: 32 }}
          aria-label="Page navigation example"
        >
          <CPaginationItem
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            Previous
          </CPaginationItem>

          {visiblePagination?.map((item: any) => {
            const ACTIVE_ITEM = paginationPage === item;

            if (item === 0) return <></>;
            return (
              <CPaginationItem
                active={ACTIVE_ITEM}
                onClick={() => setPaginationPage(item)}
              >
                {item}
              </CPaginationItem>
            );
          })}

          <CPaginationItem
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === total}
          >
            Next
          </CPaginationItem>
        </CPagination>
      )}

      <div className={`${searchQuery?.length > 0 && 'pt-6'}`}>
        <span className={`font-bold`}>
          Total offers:{' '}
          <span className="text-xl border-b-2 border-orange">{totalItems}</span>
        </span>
      </div>

      <div
        className={
          'mt-6 desktop:grid desktop:gap-6 desktop:grid-container desktop:grid-cols-3'
        }
      >
        {searchQuery?.length > 0 ? (
          <>
            {searchResults?.map((item: Offer, index) => {
              handleBadgeStyle(item?.status);

              return (
                <OfferTemplate
                  item={item}
                  status={BADGE_STATUS}
                  key={`Offer-Items-${index}`}
                  badgeTextColor={BADGE_TEXT_COLOR}
                  badgeBGColor={BADGE_BACKGROUND_COLOR}
                  onClick={() => onOfferClicked(item)}
                  badgeClassName={`font-bold`}
                  className={`
                          mt-4
                         delay-100 
                         transform
                         transition 
                         ease-in-out 
                         mobile:mt-6 
                         duration-300 
                         desktop:mt-0 
                         cursor-pointer
                         hover:scale-105
                         hover:shadow-md
                         hover:-translate-y-1`}
                />
              );
            })}
          </>
        ) : (
          <>
            {data?.map((item: Offer, index) => {
              handleBadgeStyle(item?.status);
              return (
                <OfferTemplate
                  item={item}
                  status={BADGE_STATUS}
                  key={`Offer-Items-${index}`}
                  badgeTextColor={BADGE_TEXT_COLOR}
                  badgeBGColor={BADGE_BACKGROUND_COLOR}
                  onClick={() => onOfferClicked(item)}
                  badgeClassName={`font-bold`}
                  className={`
                          mt-4
                         delay-100 
                         transform
                         transition 
                         ease-in-out 
                         mobile:mt-6 
                         duration-300 
                         desktop:mt-0 
                         cursor-pointer
                         hover:scale-105
                         hover:shadow-md
                         hover:-translate-y-1`}
                />
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default ManagementOfferScreen;
